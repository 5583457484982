import React, { useEffect, useState } from 'react';
import { Button, Grid, TextField, Snackbar, Alert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import instance from '../axios/axios';
import '../css/ReportManagement.css';
import DashboardIcon from '@mui/icons-material/Dashboard';

function DashboardManagement() {
    const [addDashboard, setAddDashboard] = useState(false);
    const [dashboardName, setDashboardName] = useState('');
    const [dashboardPath, setDashboardPath] = useState('');
    const [chartType, setChartType] = useState('');
    const [apiKey, setApiKey] = useState('');
    const [requiredFields, setRequiredFields] = useState([]);
    const [allDashboards, setAllDashboards] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');
    const [openAddDashboard, setOpenAddDashboard] = useState(false);

    const handleClick = () => {
        setOpenAddDashboard(!openAddDashboard);
    };

    useEffect(() => {
        const fetchDashboards = async () => {
            try {
                const response = await instance.post('/reports/dashboards/all', {}, { withCredentials: true });
                if (response.status === 200) {
                    setAllDashboards(response.data.data.dashboards);
                } else {
                    handleSnackbarOpen('Error fetching dashboards', 'error');
                }
            } catch (error) {
                console.error('Fetch error:', error);
                handleSnackbarOpen('Error fetching dashboards', 'error');
            }
            if (refresh) {
                setRefresh(false);
            }
        };
        fetchDashboards();
    }, [addDashboard, refresh]);

    const createPayload = () => {
        let pl;
        if (requiredFields.length === 0) {
            pl = {
                name: dashboardName,
                path: dashboardPath,
                api_key: apiKey,
                chart_type: chartType,
            };
        } else {
            const filteredParams = requiredFields.filter(param => param.field_name !== '' && param.field_type !== '');
            pl = {
                name: dashboardName,
                path: dashboardPath,
                required_params: filteredParams,
                api_key: apiKey,
                chart_type: chartType
            };
        }
        if (apiKey === '') {
            delete pl.api_key;
        }
        return pl;
    };

    const handleSnackbarOpen = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleDeleteDashboard = async (index) => {
        const response = await instance.post(`/reports/dashboards/${allDashboards[index].id}/delete`, {}, { withCredentials: true });
        if (response.status === 200) {
            const updatedDashboards = allDashboards.filter((dashboard, i) => i !== index);
            setAllDashboards(updatedDashboards);
            handleSnackbarOpen('Dashboard deleted successfully', 'success');
        } else {
            handleSnackbarOpen('Error deleting dashboard', 'error');
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const payload = createPayload();
        try {
            const response = await instance.post('/reports/dashboard/add', payload, { withCredentials: true });
            if (response.status === 200) {
                handleSnackbarOpen('Dashboard added successfully', 'success');
                setOpenAddDashboard(false);
                setDashboardName('');
                setDashboardPath('');
                setApiKey('');
                setRequiredFields([
                    { field_name: 'start_date', field_type: 'date' },
                    { field_name: 'end_date', field_type: 'date' }
                ]);
                setRefresh(true);
            } else {
                handleSnackbarOpen('Error adding dashboard', 'error');
            }
        } catch (error) {
            console.error('Submission error:', error);
            handleSnackbarOpen('Error adding dashboard', 'error');
        }
    };

    const handleRequiredFieldChange = (index, field, value) => {
        const newRequiredFields = [...requiredFields];
        newRequiredFields[index][field] = value;
        setRequiredFields(newRequiredFields);
    };

    const addRequiredField = () => {
        setRequiredFields([...requiredFields, { field_name: '', field_type: '' }]);
    };

    const removeRequiredField = (index) => {
        const newRequiredFields = requiredFields.filter((_, i) => i !== index);
        setRequiredFields(newRequiredFields);
    };

    return (
        <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} md={6} lg={4}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '20px',
                    gap: '20px',
                    width: '100%'
                }}>
                    <h2 style={{
                        fontFamily: "Lato"
                    }}>Dashboard Management</h2>
                    <Button variant="contained" color="primary" onClick={handleClick} style={{ alignSelf: 'flex-end' }}>
                        {openAddDashboard ? 'Close' : 'Add Dashboard'}
                    </Button>
                    <Dialog open={openAddDashboard} onClose={() => setOpenAddDashboard(false)}>
                        <DialogTitle>Add a New Dashboard</DialogTitle>
                        <form onSubmit={handleFormSubmit}>
                            <DialogContent>
                                <DialogContentText>
                                    Please fill out the form below to add a new dashboard.
                                </DialogContentText>
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="Dashboard Name"
                                    variant="outlined"
                                    value={dashboardName}
                                    onChange={(e) => setDashboardName(e.target.value)}
                                    helperText="Enter a unique name for the dashboard."
                                    required
                                    margin="dense"
                                />
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="Dashboard Path"
                                    variant="outlined"
                                    value={dashboardPath}
                                    onChange={(e) => setDashboardPath(e.target.value)}
                                    helperText="Enter the path to the dashboard."
                                    required
                                    margin="dense"
                                />
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="API Key"
                                    variant="outlined"
                                    value={apiKey}
                                    onChange={(e) => setApiKey(e.target.value)}
                                    helperText="Enter the API key for the dashboard."
                                    required
                                    margin="dense"
                                />
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="Chart Type"
                                    variant="outlined"
                                    value={chartType}
                                    onChange={(e) => setChartType(e.target.value)}
                                    helperText="Enter the type of chart to display."
                                    required
                                    margin="dense"
                                />
                                {requiredFields.map((param, index) => (
                                    <div key={index} style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label={`Parameter ${index + 1} Name`}
                                            variant="outlined"
                                            value={param.field_name}
                                            onChange={(e) => handleRequiredFieldChange(index, 'field_name', e.target.value)}
                                            helperText="Enter the name of the parameter."
                                            margin="dense"
                                        />
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label={`Parameter ${index + 1} Type`}
                                            variant="outlined"
                                            value={param.field_type}
                                            onChange={(e) => handleRequiredFieldChange(index, 'field_type', e.target.value)}
                                            helperText="Enter the type of the parameter."
                                            margin="dense"
                                        />
                                        <Button variant="contained" color="secondary" onClick={() => removeRequiredField(index)}>Remove</Button>
                                    </div>
                                ))}
                                <Button variant="contained" color="primary" onClick={addRequiredField} style={{ marginTop: '10px' }}>Add Required Field</Button>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setOpenAddDashboard(false)} color="primary">
                                    Cancel
                                </Button>
                                <Button type="submit" variant="contained" color="primary">
                                    Add Dashboard
                                </Button>
                            </DialogActions>
                        </form>
                    </Dialog>
                    <div className='dashboard-management'>
                        {allDashboards.length > 0 && addDashboard === false && (
                            <table>
                                <thead>
                                    <tr>
                                        <th> </th>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Path</th>
                                        <th> </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allDashboards.map((dashboard, index) => (
                                        <tr key={index}>
                                            <td><DashboardIcon /></td>
                                            <td>{index + 1}</td>
                                            <td>{dashboard.name}</td>
                                            <td>{dashboard.path}</td>
                                            <td>
                                                <Button variant="contained" color="secondary" onClick={() => handleDeleteDashboard(index)}>
                                                    Delete
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            </Grid>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Grid>
    );
}

export default DashboardManagement;