import AnimationComponent from "./AnimatedComponent";

function HomePage() {
  return (
    <div>
      <AnimationComponent />
    </div>
  );
}

export default HomePage;