import './App.css';
import { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import instance from './axios/axios';
import useAuth from './hooks/useAuth';
import NavBar from './components/NavBar';
import LoginPage from './components/Login';
import Footer from './components/Footer';



function App() {
  const { auth, setAuth } = useAuth();

  useEffect(() => {
    const validateSession = async () => {
      try {
        const response = await instance.post('/validate', {}, { withCredentials: true });
        if (response.status !== 200) {
          setAuth({});
        }
      } catch (error) {
        console.error('Session validation failed:', error);
        setAuth({});
      }
    };

    const interval = setInterval(validateSession, 120000);
    return () => clearInterval(interval);
  }, [setAuth]);

  return (
          <>
            <div className='app-container'>
              <NavBar className='App-header'/>
              <div style={{ minHeight: '700px', marginBottom: '20vh' }}>
                {auth?.auth ? <Outlet /> : <LoginPage />}
              </div>
            </div>
            <Footer />
          </>

  );
}


export default App;
