import React, { useEffect, useState } from 'react';
import Table from '@mui/joy/Table';
import { Link, useNavigate } from 'react-router-dom';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import TablePagination from '@mui/material/TablePagination';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import useAuth from '../hooks/useAuth';
import instance from '../axios/axios';
import { useParams } from 'react-router-dom';
import '../css/Nav.css';

const ItemType = 'COLUMN';

const DraggableColumn = ({ column, index, moveColumn, isReordering, isFirst, handleRequestSort }) => {
  const [, ref] = useDrag({
    type: ItemType,
    item: { index },
    canDrag: !isFirst,
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (item) => {
      if (item.index !== index && !isFirst) {
        moveColumn(item.index, index);
        item.index = index;
      }
    },
  });

  return (
    <th
      ref={isReordering && !isFirst ? (node) => ref(drop(node)) : null}
      onClick={() => handleRequestSort(column.accessor)}
      style={{
        cursor: isReordering && !isFirst ? 'move' : 'pointer',
        width: column.accessor === 'Publisher' ? '30%' : '10%',
        minWidth: '150px',
        position: isFirst ? 'sticky' : 'relative',
        left: isFirst ? 0 : 'auto',
        zIndex: isFirst ? 2 : 1,
        borderRight: isFirst ? '1px solid #E0E0E0' : 'none',
        backgroundColor: 'grey',
        color: 'white',
      }}
    >
      {column.Header}
    </th>
  );
};

function MyTable({ data, columns: initialColumns, hasChildren, childReports, startDate, endDate, onDrillThrough }) {
  const { auth, setAuth } = useAuth();
  const [columns, setColumns] = useState(initialColumns);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(initialColumns[0].accessor);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentLinks, setCurrentLinks] = useState([]);
  const [isReordering, setIsReordering] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [newHeaders, setNewHeaders] = useState({});

  const navigate = useNavigate();

  const { reportId } = useParams();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenDialog = (links) => {
    setCurrentLinks(links);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => setDialogOpen(false);

  const formatValue = (column, value) => {
    if (column.Header.includes('$')) {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
    } else if (column.Header.includes('%')) {
      return `${value}%`;
    }
    return value;
  };

  const renderCellContent = (column, row) => {
    if (!hasChildren) {
      return formatValue(column, row[column.accessor]);
    }

    const linkedReports = childReports.filter(
      (child) => child.parent_column_with_link === column.accessor
    );

    const formattedValue = formatValue(column, row[column.accessor]);

    if (linkedReports.length > 1) {
      return (
        <a
          style={{ cursor: 'pointer' }}
          onClick={() =>
            handleOpenDialog(
              linkedReports.map((report) => {
                const urlParams = new URLSearchParams();
                report.linked_params.forEach(param => {
                  urlParams.append(param.child_key, row[param.parent_value]);
                });
                return {
                  path: `/report/${report.child_report_id}?${urlParams.toString()}&start_date=${startDate}&end_date=${endDate}`,
                  label: report.child_report_label,
                  name: report.child_report_name,
                };
              })
            )
          }
        >
          {formattedValue}
        </a>
      );
    } else if (linkedReports.length === 1) {
      const report = linkedReports[0];
      const urlParams = new URLSearchParams();
      report.linked_params.forEach(param => {
        urlParams.append(param.child_key, row[param.parent_value]);
      });
      const drillThroughUrl = `/report/${report.child_report_id}?${urlParams.toString()}&start_date=${startDate}&end_date=${endDate}`;

      return (
        <Link to={drillThroughUrl}>
          {formattedValue}
        </Link>
      );
    }
    return formattedValue;
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const moveColumn = (fromIndex, toIndex) => {
    const updatedColumns = Array.from(columns);
    const [movedColumn] = updatedColumns.splice(fromIndex, 1);
    updatedColumns.splice(toIndex, 0, movedColumn);
    setColumns(updatedColumns);
  };

  const handleEditHeadersClick = () => {
    setEditDialogOpen(true);
  };

  const handleRemovePreferences = async () => {
    try {
      const response = await instance.post(`/reports/preferences/remove`, {  module: 'Reports', record_id: reportId }, { withCredentials: true });
      if (response.status === 200) {
        console.log('Preferences removed successfully');
        alert('Preferences removed successfully\nPlease refresh the page to see the changes.');
      } else {
        console.error('Error removing preferences', response);
      }
    } catch (error) {
      console.error('Error removing preferences', error);
    }
  };

  const handleSaveHeaders = async () => {
    try {
      const response = await instance.post('/reports/preferences/save', {
        module: 'Reports',
        record_id: reportId, // Update this with the actual record_id
        preferences: {
          column_names: newHeaders
        }
      }, { withCredentials: true });

      if (response.status === 200) {
        setColumns(columns.map(col => ({
          ...col,
          Header: newHeaders[col.accessor] || col.Header
        })));
        setEditDialogOpen(false);
      } else {
        console.error('Error saving header preferences', response);
      }
    } catch (error) {
      console.error('Error saving header preferences', error);
    }
  };

  const handleSaveColumnOrder = async () => {
    try {
      const columnOrder = columns.map(column => column.accessor);
      const response = await instance.post('/users/preferences/save', {
        module: 'Reports',
        record_id: reportId,
        preferences: {
          column_order: columnOrder
        }
      }, { withCredentials: true });

      if (response.status === 200) {
        console.log('Column order saved successfully');
      } else {
        console.error('Error saving column order', response);
      }
    } catch (error) {
      console.error('Error saving column order', error);
    }
  };



  return (
    <>
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle sx={{ alignSelf: 'center' }}>Select a report</DialogTitle>
        <DialogActions
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '1rem',
            padding: '1rem',
          }}
        >
          {currentLinks.map((link) => (
            <Button key={link.label} onClick={handleCloseDialog} component={Link} to={link.path}>
              {link.label}{link.name}
            </Button>
          ))}
        </DialogActions>
      </Dialog>
      <Button onClick={() => setIsReordering(!isReordering)} style={{ marginBottom: '1rem' }}>
        {isReordering ? 'Stop Reordering' : 'Reorder Columns'}
      </Button>
      {isReordering && (
        <Button onClick={handleSaveColumnOrder} style={{ marginBottom: '1rem' }}>
          Save Column Order
        </Button>
      )}
      {auth.is_admin && (
        <>
          <Button onClick={handleEditHeadersClick} style={{ marginBottom: '1rem' }}>
            <DriveFileRenameOutlineIcon /> Edit Headers
          </Button>
          <Button onClick={handleRemovePreferences} style={{ marginBottom: '1rem' }}>
            Remove Preferences
          </Button>
          <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
            <DialogTitle>Edit Column Headers</DialogTitle>
            <DialogContent>
              {columns.map((column) => (
                <TextField
                  key={column.accessor}
                  label={column.Header}
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  value={newHeaders[column.accessor] || column.Header}
                  onChange={(e) => setNewHeaders({
                    ...newHeaders,
                    [column.accessor]: e.target.value
                  })}
                />
              ))}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setEditDialogOpen(false)} color="primary">
                Cancel
              </Button>
              <Button onClick={handleSaveHeaders} color="primary" variant="contained">
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
      <DndProvider backend={HTML5Backend}>
        <div
          style={{
            overflowX: 'auto',
            border: '1px solid #ccc',
            borderRadius: '4px',
            boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
          }}
        >
          <Table hoverRow sx={{ width: 'auto', borderCollapse: 'collapse' }}>
            <thead>
              <tr className="table-header" style={{ zIndex: 2 }}>
                {columns.map((column, index) => (
                  <DraggableColumn
                    key={column.accessor}
                    column={column}
                    index={index}
                    handleRequestSort={handleRequestSort}
                    moveColumn={moveColumn}
                    isReordering={isReordering}
                    isFirst={index === 0}
                  />
                ))}
              </tr>
            </thead>
            <tbody>
              {stableSort(data, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {columns.map((column, columnIndex) => (
                      <td
                        key={`${rowIndex}-${column.accessor}`}
                        style={{
                          minWidth: '150px',
                          color: '#ddd',
                          position: columnIndex === 0 ? 'sticky' : 'relative',
                          left: columnIndex === 0 ? '0' : 'auto',
                          zIndex: columnIndex === 0 ? 1 : 'auto',
                          borderRight: columnIndex === 0 ? '1px solid #E0E0E0' : 'none',
                          backgroundColor: rowIndex % 2 === 0 ? '#444444' : '#555555', // Alternate row colors
                        }}
                      >
                        {renderCellContent(column, row)}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </DndProvider>
      <TablePagination
        component="div"
        count={data.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ color: 'white' }}
      />
    </>
  );
}

export default MyTable;
