import { useState } from 'react';
import instance from '../axios/axios';
import useAuth from '../hooks/useAuth';
import { Stack, TextField, Button } from '@mui/material';
import '../css/LoginPage.css';

function LoginPage({ onLogin }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { auth, setAuth } = useAuth();
    
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await instance.post('/login', { username, password }, { withCredentials: true });
            if (response.status === 200) {
                setAuth({auth: true, user: response.data.user_store.user_id, is_admin: response.data.user_store.is_admin});
            }
        }
        catch (error) {
            if (error.response.status === 401) {
                alert('Invalid username or password');
            }
        }
    }
    
    return (
        <div className='login-form'>
          <form onSubmit={handleFormSubmit}>
            <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
              <TextField 
                type="text" 
                label="Username" 
                variant="outlined" 
                value={username} 
                onChange={(e) => setUsername(e.target.value)} 
                sx={{
                  backgroundColor: 'white',
                  borderRadius: '5px',
                  color: 'black'
                }}
                required 
              />
              <TextField 
                type="password" 
                label="Password" 
                variant="outlined" 
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
                sx={{
                  backgroundColor: 'white',
                  borderRadius: '5px',
                  color: 'black'
                }}
                required 
              />
              <Button type="submit" variant="contained" color="primary">
                Login
              </Button>
            </Stack>
          </form>
        </div>
      );
}

export default LoginPage;