// AnimationComponent.jsx
import React from 'react';
import { motion } from 'framer-motion';
import '../css/AnimatedComponent.css';

const circleVariants = {
  initial: {
    y: 0
  },
  animate: i => ({
    y: [0, -20, 0],
    transition: {
      y: {
        duration: 0.6,
        ease: 'easeInOut',
        repeat: Infinity,
        delay: i * 0.2,
      }
    }
  })
};

const AnimationComponent = () => {
  return (
    <div className="animation-container">
      <div className="animation">
        {[0, 1, 2, 3, 4, 5, 6, 7, 8].map(i => (
          <motion.div
            key={i}
            className="circle"
            custom={i}
            variants={circleVariants}
            initial="initial"
            animate="animate"
          />
        ))}
      </div>
      <h1 style={{
        fontFamily: 'Lato',
      }}>A Really Nice Homepage is Coming Soon..</h1>
    </div>
  );
};

export default AnimationComponent;
