import { Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import Unauthorized from './Unauthorized';

const ProtectedRoute = ({ children }) => {
  const { auth } = useAuth();

  // Check if authenticated and is admin
  if (!auth?.auth || !auth?.is_admin) {
    // Redirect to login page or home page if not authenticated or not admin
    return <Unauthorized />;
  }

  // If authenticated and is admin, render the children components
  return children;
};

export default ProtectedRoute;