import React, { useEffect, useState } from 'react';
import Breadcrumbs from '@mui/joy/Breadcrumbs';
import { Link } from 'react-router-dom';
import { Menu, MenuItem, IconButton } from '@mui/material';
import useAuth from '../hooks/useAuth';
import instance from '../axios/axios';
import logo from '../assets/medigaplogotransparent2.svg';
import '../css/Nav.css';

function NavBar() {
    const { auth, setAuth } = useAuth();
    const [navItems, setNavItems] = useState([]);
    const [apps, setApps] = useState([]);

    const [anchorElReports, setAnchorElReports] = useState(null);
    const [anchorElApps, setAnchorElApps] = useState(null);

    const openReports = Boolean(anchorElReports);
    const openApps = Boolean(anchorElApps);

    const handleReportsClick = (event) => {
        setAnchorElReports(event.currentTarget);
        setAnchorElApps(null); // Close the other menu
    };

    const handleAppsClick = (event) => {
        setAnchorElApps(event.currentTarget);
        setAnchorElReports(null); // Close the other menu
    };

    const handleClose = () => {
        setAnchorElReports(null);
        setAnchorElApps(null);
    };

    const logout = async (e) => {
        e.preventDefault();
        try {
            const response = await instance.post('/logout', {}, { withCredentials: true });
            if (response.status === 200) {
                setAuth({});
            } else {
                alert('Error logging out');
            }
        } catch (error) {
            alert('Error logging out');
        }
    };

    useEffect(() => {
        if (auth?.auth) {
            instance.get('/users/access_list_from_jwt', { withCredentials: true })
                .then((response) => {
                    if (response.status === 200) {
                        console.log('fetched reports')
                        const accessList = response.data.data.access;
                        setNavItems(accessList);
                    } else {
                        console.log('Error getting access list');
                    }
                })
                .catch((error) => {
                    console.log('Error getting access list', error);
                });
        }
    }, [anchorElReports]);

    useEffect(() => {
        const fetchApps = async () => {
            try {
                const response = await instance.get('/users/apps/access_list_from_jwt', { withCredentials: true });
                if (response.status === 200) {
                    setApps(response.data.data.access);
                }
            } catch (error) {
                console.log('Error getting applications', error);
            }
        };
        if (auth?.auth) {
            fetchApps();
        }
    }, [anchorElApps]);

    return (
        <div className="nav-container">
            <Link to='/'><img src={logo} alt="Medigap Vision Logo" className="logo-image" /></Link>
            <div className="header-container header-font">
                <div style={{ display: 'flex', alignItems: 'center', flex: 1, marginLeft: '60px' }}>
                    <Breadcrumbs aria-label="breadcrumb">
                        {auth?.auth && (
                            <>
                                <Link to="/dashboard" className="nav-link" style={{
                                    color: '#E0E0E0',
                                    marginRight: '2rem',
                                    '&:hover': {
                                        color: '#FFFFFF',
                                        backgroundColor: '#555555',
                                        textDecoration: 'underline'
                                    }
                                }}>Dashboard</Link>
                                <IconButton
                                    aria-label="reports"
                                    aria-controls="reports-menu"
                                    aria-haspopup="true"
                                    onClick={handleReportsClick}
                                    size="small"
                                    sx={{
                                        color: '#E0E0E0',
                                        marginRight: '2rem',
                                        '&:hover': {
                                            color: '#FFFFFF',
                                            backgroundColor: '#555555',
                                            textDecoration: 'underline'
                                        }
                                    }}
                                >
                                    Reports
                                </IconButton>
                                <IconButton
                                    aria-label="applications"
                                    aria-controls="apps-menu"
                                    aria-haspopup="true"
                                    onClick={handleAppsClick}
                                    size="small"
                                    sx={{
                                        color: '#E0E0E0',
                                        marginRight: '2rem',
                                        '&:hover': {
                                            color: '#FFFFFF',
                                            backgroundColor: '#555555',
                                            textDecoration: 'underline'
                                        }
                                    }}
                                >
                                    Applications
                                </IconButton>
                                <Menu
                                    id="reports-menu"
                                    anchorEl={anchorElReports}
                                    keepMounted
                                    open={openReports}
                                    onClose={handleClose}
                                    PaperProps={{
                                        style: {
                                            backgroundColor: '#2A2A2A',
                                        },
                                    }}
                                >
                                    {navItems.map((item) => (
                                        <MenuItem key={item.id} onClick={handleClose}>
                                            <Link to={`/report/${item.id}`} className="nav-link">{item.name}</Link>
                                        </MenuItem>
                                    ))}
                                </Menu>
                                <Menu
                                    id="apps-menu"
                                    anchorEl={anchorElApps}
                                    keepMounted
                                    open={openApps}
                                    onClose={handleClose}
                                    PaperProps={{
                                        style: {
                                            backgroundColor: '#2A2A2A',
                                        },
                                    }}
                                >
                                    {apps.map((app) => (
                                        <MenuItem key={app.id} onClick={handleClose}>
                                            <Link to={`/app/${app.id}`} className="nav-link">{app.name}</Link>
                                        </MenuItem>
                                    ))}
                                </Menu>
                                {auth.is_admin && (
                                    <Link to="/admin" className="nav-link">Admin</Link>
                                )}
                            </>
                        )}
                        {!auth?.auth && (
                            <Link to="/login" className="nav-link">Login</Link>
                        )}
                    </Breadcrumbs>
                </div>
                {auth?.auth && (
                    <button onClick={logout} className="nav-button">Logout</button>
                )}
            </div>
        </div>
    );
}

export default NavBar;