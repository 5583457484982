import { useEffect, useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import instance from '../axios/axios';
import MyTable from './DynamicTable';
import '../css/TableStyles.css';
import Button from '@mui/joy/Button';
import Input from '@mui/joy/Input';
import Zoom from '@mui/material/Zoom';
import Stack from '@mui/joy/Stack';
import CircularProgress from '@mui/material/CircularProgress';

function Reporting() {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [reqParamaters, setReqParameters] = useState([]);
  const [page_name, setPageName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [hasChildren, setHasChildren] = useState(false);
  const [childReports, setChildReports] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [loadFromParams, setLoadFromParams] = useState(false);
  const location = useLocation();
  const [hasParent, setHasParent] = useState(false);
  const [parentReport, setParentReport] = useState(null);
  const [displayBackButton, setDisplayBackButton] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [filterText, setFilterText] = useState('');

  const searchParams = new URLSearchParams(location.search);

  let { reportId } = useParams();
  const navigate = useNavigate();

  const goBackToParentReport = async () => {
    const result = await instance.get(`/reports/view/${parentReport.parent_report_id}`, { withCredentials: true });
    if (result.status !== 200) {
        console.error("Error fetching parent report:", result);
    } else {
      if (result.data.data.parent_reports.length > 0) {
        navigate(-1); // Go back one step in the history (same as clicking the browser back button)
        setDisplayBackButton(false)
      } else {
        const parentId = parentReport?.parent_report_id; // Make sure parentReport is correctly set
        const startDate = searchParams.get('start_date');
        const endDate = searchParams.get('end_date');
        const parentUrl = `/report/${parentId}?start_date=${startDate}&end_date=${endDate}`;
        navigate(parentUrl);
        setDisplayBackButton(false);
      }
    }
  };

  const handleFilterChange = (event) => {
    const value = event.target.value;
    setFilterText(value);
    const lowercasedValue = value.toLowerCase();
    const filtered = data.filter(item =>
      Object.keys(item).some(key =>
        item[key].toString().toLowerCase().includes(lowercasedValue)
      )
    );
    setFilteredData(filtered);
  };

  useEffect(() => {
    setData([]);
    setColumns([]);
    setReqParameters([]); // Assuming you want to clear this as well
    setStartDate('');
    setEndDate('');
    setIsLoading(false);
    const fetchChildReports = async () => {
      try {
        const response = await instance.get(`/reports/view/${reportId}`, { withCredentials: true });
        if (response.status === 200) {
          if (response.data.data.child_reports.length > 0) {
            setHasChildren(true);
            setChildReports(response.data.data.child_reports);
          } else {
            setHasChildren(false);
          }
          if (response.data.data.parent_reports.length > 0) {
            setHasParent(true);
            setDisplayBackButton(true);
            setParentReport(response.data.data.parent_reports[0]);
          }
        } else {
          console.error("Error fetching child reports:", response);
        }
      } catch (error) {
        console.error("Error fetching child reports:", error);
      }
    }
    fetchChildReports();
  }, [reportId]);

  useEffect(() => {
    if (!filterText) {
      setFilteredData(data); // Reset if filter is cleared
    }
  }, [filterText, data]);

  useEffect(() => {
    // Update the reqParameters with values from the URL
    const newParams = [];
    searchParams.forEach((value, key) => {
      newParams.push({ field_name: key, value: value });
      // Also update startDate and endDate if they are part of the URL search params
      if (key === 'start_date') {
        setStartDate(value);
      } else if (key === 'end_date') {
        setEndDate(value);
      }
    });
    setReqParameters(newParams);
    
    // Define what should happen after the URL search params have been updated
    const updateData = async () => {
      setIsLoading(true);
      setLoadFromParams(true);
      try {
        const reportingURL = `/reports/${reportId}`;
        const payload = preparePayload(newParams);
        const response = await instance.post(reportingURL, payload, { withCredentials: true });
        if (response.status === 200) {
          setIsLoading(false);
          const responseData = response.data;
    
          const relevantData = responseData[0]; 
    
          setData(relevantData);
    
          if (relevantData.length > 0) {
            const firstItemKeys = Object.keys(relevantData[0]);
            const newColumns = firstItemKeys.map(key => ({
              Header: key.replace(/([A-Z])/g, ' $1').trim(), // Add space before capital letters
              accessor: key,
            }));
            setColumns(newColumns);
          }
        } else {
          console.error("Error fetching data:", response);
        }
      } catch (error) {
        console.error("Error fetching report parameters:", error);
      } finally {
        setIsLoading(false);
      }
    };
  
    // Call the updateData function if the URL search params have changed
    if (newParams.length > 0) {
      updateData();
    }
  }, [location.search]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (reportId) {
          const response = await instance.get(`/reports/view/${reportId}`, { withCredentials: true });
          if (response.status === 200) {
            let requiredParams = response.data.data.required_params.filter(param => !param.field_type.startsWith('$'));
            
            // Check URL parameters and prefill if available
            requiredParams = requiredParams.map(param => {
              const urlValue = searchParams.get(param.field_name);
              return { ...param, value: urlValue ? urlValue : '' };
            });
  
            setReqParameters(requiredParams);
            setPageName(response.data.data.name);
            
            if (!location.search && !loadFromParams) {
              setData([]);
              setColumns([]);
            }
          }
        }
      } catch (error) {
        console.error("Error fetching report parameters:", error);
      }
    };
  
    fetchData();
  }, [reportId, location.search]);


  function convertToCSV(data) {
    const csvRows = [];
    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(','));
  
    for (const row of data) {
      const values = headers.map(header => {
        const escaped = ('' + row[header]).replace(/"/g, '\\"'); 
        return `"${escaped}"`;
      });
      csvRows.push(values.join(','));
    }
    return csvRows.join('\n');
  }

  function downloadCSV(data) {
    const csvString = convertToCSV(data);
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'data.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const preparePayload = (parameters) => {
    const payload = {};
    parameters.forEach(param => {
      if (param.value !== undefined) {
        payload[param.field_name] = param.value;
      }
    });
    return payload;
  };

const handleInputChange = (index, newValue) => {
  setReqParameters(currentReqParameters =>
    currentReqParameters.map((param, i) =>
      i === index ? { ...param, value: newValue } : param
    )
  );
};

  const handleSubmit = async (e) => {
    e.preventDefault();
    setData([]);
    setColumns([]);
    setIsLoading(true);
    const startDateParam = reqParamaters.find(param => param.field_name === 'start_date');
    const endDateParam = reqParamaters.find(param => param.field_name === 'end_date');

    if (startDateParam && endDateParam) {
      setStartDate(startDateParam.value);
      setEndDate(endDateParam.value);
    }
  
    try {
      const reportingURL = `/reports/${reportId}`;
      const payload = preparePayload(reqParamaters);
      const response = await instance.post(reportingURL, payload, 
                                                  { withCredentials: true });
      if (response.status === 200) {
        setIsLoading(false);
        const responseData = response.data;
  
        const relevantData = responseData[0]; 
  
        setData(relevantData);
  
        if (relevantData.length > 0) {
          const firstItemKeys = Object.keys(relevantData[0]);
          const newColumns = firstItemKeys.map(key => ({
            Header: key.replace(/([A-Z])/g, ' $1').trim(), // Add space before capital letters
            accessor: key,
          }));
          setColumns(newColumns);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response && error.response.status === 401) {
          alert('Invalid date range');
      }
    }
  }

  const handleDrillThrough = (childReportId, linkedParams) => {
    const urlParams = new URLSearchParams();
    linkedParams.forEach(param => {
      const parentValue = data.find(row => row[param.parent_value]);
      if (parentValue) {
        urlParams.append(param.child_key, parentValue[param.parent_value]);
      }
    })
    const drillThroughUrl = `/report/${childReportId}?${urlParams.toString()}`;
    navigate(drillThroughUrl);
  }

  return (
    <div>
        <form onSubmit={handleSubmit}>
          <div className='header'>
            <h1 className='page-title' style={{color: 'white', fontFamily: "Lato"}}>{page_name}</h1>
          </div>
          <div className='date_selector'>
            <Stack spacing={1} direction="row">
            {
              reqParamaters && reqParamaters.map((param, index) => (
                <Input key={index} 
                      type={param.field_type}
                      placeholder={param.field_name} 
                      name={param.field_name} 
                      label={param.field_name}
                      value={param.value || ''} // Controlled component must have a value
                      onChange={(e) => handleInputChange(index, e.target.value)}
                      sx={{ backgroundColor: '#333', color: '#E0E0E0' }}
                    />
              ))
            }
                <Button type="submit" className='submit-button'>Submit</Button>
                <Button onClick={() => downloadCSV(data)} className='download-button'>
                  Download CSV
                </Button>
                {displayBackButton && (
                  <Button onClick={goBackToParentReport} className='back-button'>
                    Back to {parentReport?.parent_report_name}
                  </Button>
                )}
                  <Input
                    placeholder="Filter..."
                    value={filterText}
                    onChange={handleFilterChange}
                    sx={{ backgroundColor: '#333', color: '#E0E0E0' }}
                  />
            </Stack>
          </div>
        </form>
        <Zoom in={true} style={{ transitionDelay: '500ms' }}>
        <div style={{ margin: '5vh', padding: '2vh', overflowX: 'auto' }} className='responsive-table'>
        {isLoading && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
            <CircularProgress />
          </div>
        )}
        {!isLoading && columns.length > 0 && data.length > 0 && (
          <MyTable 
            data={filteredData} 
            columns={columns}
            hasChildren={hasChildren}
            childReports={childReports}
            startDate={startDate}
            endDate={endDate}
            onDrillThrough={handleDrillThrough} // Pass the drill-through handler to the MyTable element
          />
        )}
        </div>
        </Zoom>
    </div>

  );
}

export default Reporting;