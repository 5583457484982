import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createBrowserRouter, RouterProvider, Route } from 'react-router-dom';
import { AuthProvider } from './context/AuthProvider';
import Admin from './components/Admin';
import Reporting from './components/Reporting';
import ProtectedRoute from './components/ProtectedRoute';
import HomePage from './components/HomePage';
import DynamicApplicationComponent from './components/RenderApplication';
import Dashboard from './components/Dashboard';
import App from './App';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: <HomePage />
      },
      {
        path: '/report/:reportId',
        element: <Reporting />
      },
      {
        path: '/dashboard',
        element: <Dashboard />
      },
      {
        path: '/admin',
        element: <ProtectedRoute><Admin /></ProtectedRoute>
      },
      {
        path: '/app/:applicationId',
        element: <DynamicApplicationComponent />
      }
    ]
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  </React.StrictMode>
);

