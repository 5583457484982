function Footer() {
  return (
<footer style={{
    backgroundColor: '#333333',
    padding: '10px 0',  // Reduce padding or make it specific to top/bottom if needed
    position: 'fixed',
    bottom: 0,
    width: '100%',
    borderTop: '1px solid #E0E0E0',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    zIndex: 2
}}>
  <div className="footer__content" style={{width: '100%', textAlign: 'center'}}>
    <p className="footer__text">© 2024 Medigap Vision</p>
  </div>
</footer>
  );
}

export default Footer;