import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import UserManagement from './UserManagement';
import ReportManagement from './ReportManagement';
import RoleManagement from './RoleManagement';
import DrillThrough from './DrillThrough';
import DashboardManagement from './DashboardManagement';
import AppManagement from './AppManagement';

function Admin() {
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <h1 style={{
                textAlign: 'center',
                marginBottom: '2vh',
                fontFamily: "Lato"
            }}>Admin Tasks</h1>
            <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="Admin tasks tabs"
                centered  
                variant="fullWidth"  
                indicatorColor="primary" 
                textColor="primary"
                sx={{ marginBottom: '2vh' }} 
            >
                <Tab label="Users" sx={{color: 'white'}}/>
                <Tab label="Reports" sx={{color: 'white'}} />
                <Tab label="Dashboards" sx={{color: 'white'}} />
                <Tab label="DrillThroughs" sx={{color: 'white'}} />
                <Tab label="Roles" sx={{color: 'white'}} />
                <Tab label="Applications" sx={{color: 'white'}} />
            </Tabs>
            {tabValue === 0 && <UserManagement />}
            {tabValue === 1 && <ReportManagement />}
            {tabValue === 2 && <DashboardManagement />}
            {tabValue === 3 && <DrillThrough />}
            {tabValue === 4 && <RoleManagement />}
            {tabValue === 5 && <AppManagement />}
        </Box>
    );
}

export default Admin;
