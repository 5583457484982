import React, { useEffect, useState } from 'react';
import { Button, Grid, TextField, Snackbar, Alert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, MenuItem, Select, InputLabel } from '@mui/material';
import instance from '../axios/axios';
import '../css/ReportManagement.css';
import DescriptionIcon from '@mui/icons-material/Description';


function ReportManagement() {
    const [addReport, setAddReport] = useState(false);
    const [reportName, setReportName] = useState('');
    const [reportPath, setReportPath] = useState('');
    const [apiKey, setApiKey] = useState('');
    const [requiredFields, setRequiredFields] = useState([
        {field_name: 'start_date', field_type: 'date'},
        {field_name: 'end_date', field_type: 'date'}
    ]);
    const [allReports, setAllReports] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');
    const [openAddReport, setOpenAddReport] = useState(false);
    const [openAddSqlReport, setOpenAddSqlReport] = useState(false);
    const [reportQuery, setReportQuery] = useState('');
    const [sqlDatabase, setSqlDatabase] = useState('');

    const handleClick = () => {
        setOpenAddReport(!openAddReport);
    };

    const handleClickSql = () => {
        setOpenAddSqlReport(!openAddSqlReport);
    }

    useEffect(() => {
        const fetchReports = async () => {
            try {
                const response = await instance.post('/reports/all', {}, {withCredentials: true});
                if (response.status === 200) {
                    setAllReports(response.data.data.reports);
                } else {
                    handleSnackbarOpen('Error fetching reports', 'error');
                }
            } catch (error) {
                console.error('Fetch error:', error);
                handleSnackbarOpen('Error fetching reports', 'error');
            }
            if (refresh) {
                setRefresh(false);
            }
        };
        fetchReports();
    }, [addReport, refresh]);


    const createPayload = () => {
        const filteredParams = requiredFields.filter(param => param.field_name !== '' && param.field_type !== '');
        return {
            name: reportName,
            path: reportPath,
            required_params: filteredParams,
            api_key: apiKey
        };
    };

    const createSqlPayload = () => {
        return {
            name: reportName,
            query: reportQuery,
            database: sqlDatabase
        };
    };


    const handleSnackbarOpen = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleDeleteReport = async (index) => {
        const response = await instance.post(`/reports/${allReports[index].id}/delete`, {}, {withCredentials: true});
        if (response.status === 200) {
            const updatedReports = allReports.filter((report, i) => i !== index);
            setAllReports(updatedReports);
            handleSnackbarOpen('Report deleted successfully', 'success');
        } else {
            handleSnackbarOpen('Error deleting report', 'error');
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const payload = createPayload();
        try {
            const response = await instance.post('/reports/add', payload, {withCredentials: true});
            if (response.status === 200) {
                handleSnackbarOpen('Report added successfully', 'success');
                setOpenAddReport(false);
                setReportName('');
                setReportPath('');
                setApiKey('');
                setRequiredFields([
                    {field_name: 'start_date', field_type: 'date'},
                    {field_name: 'end_date', field_type: 'date'}
                ]);
                setRefresh(true);
            } else {
                handleSnackbarOpen('Error adding report', 'error');
            }
        } catch (error) {
            console.error('Submission error:', error);
            handleSnackbarOpen('Error adding report', 'error');
        }
    };

    const handleSqlFormSubmit = async (e) => {
        e.preventDefault();
        const payload = createSqlPayload();
        try {
            const response = await instance.post(
                '/middleware/report',
                payload,
                { withCredentials: true }
            );
            if (response.status === 200) {
                handleSnackbarOpen('Report added successfully', 'success');
                setOpenAddSqlReport(false);
                setReportName('');
                setReportQuery('');
                setSqlDatabase('');
                setRefresh(true);
            } else {
                handleSnackbarOpen('Error adding report', 'error');
            }
        } catch (error) {
            console.error('Submission error:', error);
            handleSnackbarOpen('Error adding report', 'error');
        }
    };

    const handleRequiredFieldChange = (index, field, value) => {
        const newRequiredFields = [...requiredFields];
        newRequiredFields[index][field] = value;
        setRequiredFields(newRequiredFields);
    };

    const addRequiredField = () => {
        setRequiredFields([...requiredFields, {field_name: '', field_type: ''}]);
    };

    const removeRequiredField = (index) => {
        const newRequiredFields = requiredFields.filter((_, i) => i !== index);
        setRequiredFields(newRequiredFields);
    };

    return (
        <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} md={6} lg={4}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '20px',
                    gap: '20px',
                    width: '100%'
                }}>
                    <h2 style={{
                        fontFamily: "Lato"
                    }}>Report Management</h2>
                    <Button variant="contained" color="primary" onClick={handleClick} style={{ alignSelf: 'flex-end' }}>
                        {openAddReport ? 'Close' : 'Add Report'}
                    </Button>
                    <Dialog open={openAddReport} onClose={() => setOpenAddReport(false)}>
                        <DialogTitle>Add a New Report</DialogTitle>
                        <form onSubmit={handleFormSubmit}>
                            <DialogContent>
                                <DialogContentText>
                                    Please fill out the form below to add a new report.
                                </DialogContentText>
                                <TextField 
                                    fullWidth
                                    type="text" 
                                    label="Report Name" 
                                    variant="outlined"
                                    value={reportName}
                                    onChange={(e) => setReportName(e.target.value)}
                                    helperText="Enter a unique name for the report." 
                                    required 
                                    margin="dense"
                                />
                                <TextField
                                    fullWidth
                                    type="text" 
                                    label="Report Path" 
                                    variant="outlined"
                                    value={reportPath}
                                    onChange={(e) => setReportPath(e.target.value)}
                                    helperText="Enter the path to the report." 
                                    required
                                    margin="dense"
                                />
                                <TextField
                                    fullWidth
                                    type="text" 
                                    label="API Key" 
                                    variant="outlined"
                                    value={apiKey}
                                    onChange={(e) => setApiKey(e.target.value)}
                                    helperText="Enter the API key for the report." 
                                    required
                                    margin="dense"
                                />
                                {requiredFields.map((param, index) => (
                                    <div key={index} style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label={`Parameter ${index + 1} Name`}
                                            variant="outlined"
                                            value={param.field_name}
                                            onChange={(e) => handleRequiredFieldChange(index, 'field_name', e.target.value)}
                                            helperText="Enter the name of the parameter."
                                            margin="dense"
                                        />
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label={`Parameter ${index + 1} Type`}
                                            variant="outlined"
                                            value={param.field_type}
                                            onChange={(e) => handleRequiredFieldChange(index, 'field_type', e.target.value)}
                                            helperText="Enter the type of the parameter."
                                            margin="dense"
                                        />
                                        <Button variant="contained" color="secondary" onClick={() => removeRequiredField(index)}>Remove</Button>
                                    </div>
                                ))}
                                <Button variant="contained" color="primary" onClick={addRequiredField} style={{ marginTop: '10px' }}>Add Required Field</Button>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setOpenAddReport(false)} color="primary">
                                    Cancel
                                </Button>
                                <Button type="submit" variant="contained" color="primary">
                                    Add Report
                                </Button>
                            </DialogActions>
                        </form>
                    </Dialog>
                    <Button variant="contained" onClick={handleClickSql} style={{ alignSelf: 'flex-end', backgroundColor: '#27B054', color: '#FFFFFF' }}>
                        {openAddSqlReport ? 'Close' : 'Add SQL Report'}
                    </Button>
                    <Dialog open={openAddSqlReport} onClose={() => setOpenAddSqlReport(false)}>
                        <DialogTitle>Add a SQL Report</DialogTitle>
                        <form onSubmit={handleSqlFormSubmit}>
                            <DialogContent>
                                <DialogContentText>
                                    Please fill out the form below to add a new SQL report.
                                </DialogContentText>
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="Report Name"
                                    variant="outlined"
                                    value={reportName}
                                    onChange={(e) => setReportName(e.target.value)}
                                    helperText="Enter a unique name for the report."
                                    required
                                    margin="dense"
                                />
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="SQL Query"
                                    variant="outlined"
                                    rows={10}
                                    multiline
                                    value={reportQuery}
                                    onChange={(e) => setReportQuery(e.target.value)}
                                    helperText="Enter the SQL query for the report."
                                    required
                                    margin="dense"
                                />
                                <FormControl fullWidth variant="outlined" margin="dense">
                                    <InputLabel id="database-select-label">SQL Database</InputLabel>
                                    <Select
                                        labelId="database-select-label"
                                        id="database-select"
                                        value={sqlDatabase}
                                        onChange={(e) => setSqlDatabase(e.target.value)}
                                        label="SQL Database"
                                        >
                                        <MenuItem value="MGLCRM">MGLCRM</MenuItem>
                                        <MenuItem value="INFUTOR">INFUTOR</MenuItem>
                                        <MenuItem value="CALL_LOG">CALL_LOG</MenuItem>
                                        <MenuItem value="ILE">ILE</MenuItem>
                                        <MenuItem value="ILE_ZIP">ILE_ZIP</MenuItem>
                                        <MenuItem value="ENRICHMENT">ENRICHMENT</MenuItem>
                                        <MenuItem value="POSTGRES">POSTGRES</MenuItem>
                                    </Select>
                                </FormControl>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setOpenAddSqlReport(false)} color="primary">
                                    Cancel
                                </Button>
                                <Button type="submit" variant="contained" color="primary">
                                    Add SQL Report
                                </Button>
                            </DialogActions>
                        </form>
                    </Dialog>
                    <div className='report-management'>
                        {allReports.length > 0 && addReport === false && (
                            <table>
                                <thead>
                                    <tr>
                                        <th> </th>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Path</th>
                                        <th> </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allReports.map((report, index) => (
                                        <tr key={index}>
                                            <td><DescriptionIcon /></td>
                                            <td>{index + 1}</td>
                                            <td>{report.name}</td>
                                            <td>{report.path}</td>
                                            <td>
                                                <Button variant="contained" color="secondary" onClick={() => handleDeleteReport(index)}>
                                                    Delete
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            </Grid>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Grid>
    );
}

export default ReportManagement;
