import { createContext, useState, useEffect } from 'react';
import instance from '../axios/axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({});

    useEffect(() => {
        const checkAuthStatus = async () => {
            try {
                const response = await instance.post('/validate', {}, { withCredentials: true });
                if (response.status === 200) {
                    setAuth({ auth: true, user: response.data.user_id, is_admin: response.data.is_admin, username: response.data.user_name});
                } else {
                    setAuth({});
                }
            } catch (error) {
                setAuth({});
            }
        };

        checkAuthStatus();
    }, []);

    return (
        <AuthContext.Provider value={{ auth, setAuth }}>
            {children}
        </AuthContext.Provider>
    );
}

export default AuthContext;